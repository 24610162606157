import React from 'react';
import PropTypes from 'prop-types';
import TeamCard from './team-card';
function TeamCardList(props) {
  return (
    <>
      <div className="site-wide">
        <div className="team-cards grid-container grid-container--3">
          {props.nodes &&
            props.nodes.map((node, index) => <TeamCard {...node} isInList key={index} />)}
        </div>
      </div>
    </>
  );
}

TeamCardList.propTypes = {
  nodes: PropTypes.array
};





export default TeamCardList;
