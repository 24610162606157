import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../components/global/container';
import GetInTouch from '../components/global/get-in-touch';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import InsightSmallCardList from '../components/insights/insight-small-card-list';
import PortableText from '../components/portableText';
import TeamCardList from '../components/team/team-card-list';
import Layout from '../containers/layout';
import { imageUrlFor } from '../lib/image-url';
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
  buildImageObj
} from '../lib/helpers';

export const query = graphql`
  query TeamPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawInsightscopy
    }
    static: sanityPage(slug: { current: { eq: "team-page" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      media {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            _id
          }
        }
        alt
      }
    }
    insights: allSanityInsight(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
        }
      }
    }
    posts: allSanityTeam(sort: { fields: order }) {
      edges {
        node {
          id
          order
          name
          slug {
            current
          }
          role
          image2 {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            video {
              asset {
                _id
              }
            }
            alt
          }
        }
      }
    }
  }
`;

const TeamPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;
  const media = content.media;
  const teamNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
  const insights = (data || {}).insights
    ? mapEdgesToNodes(data.insights)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}

        <Container>

          {teamNodes && <TeamCardList nodes={teamNodes} />}
          {insights && <InsightSmallCardList nodes={insights} cta copy={site} />}

          <section
            className='team--photo'
            style={{'backgroundImage': `url(${imageUrlFor(buildImageObj(media[0].image))})`}}
          >

          </section>
        </Container>
      </Layout>
    </>
  );
};

TeamPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default TeamPage;
