import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj, getTeamUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

function TeamCard(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.75
  });
  return (
    <Link
      to={getTeamUrl(props.slug.current)}
      className={`grid-column team-card ${inView ? 'in-view' : ''}`}
      ref={ref}
    >
      <div className="team-card__image">
        {props.image2.image && props.image2.image.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.image2.image))
              .url()}
            alt={props.image2.alt}
          />
        )}
      </div>
      <div className="team-card__details">
        <h2>{props.name}</h2>
        <p className="small">{props.role}</p>
      </div>
    </Link>
  );
}

TeamCard.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.object,
  role: PropTypes.string,
  image2: PropTypes.object
};

export default TeamCard;
